import React, { useEffect, useRef, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import axios from "../api/axios";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import ProductCard from "../components/ProductCard";
import Cart from "../components/Cart";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Remove } from "@mui/icons-material";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

const Products = () => {
    const navigate = useNavigate();
    const outlet = localStorage.getItem("outlet");
    const cartId = localStorage.getItem("cartId");
    const isEventOutlet = JSON.parse(localStorage.getItem("isEventOutlet"));
    const [productInfo, setProductInfo] = useState({});
    const [totalOfferPrice, setTotalOfferPrice] = useState(0);
    const [totalMrp, setTotalMrp] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedAddons, setSelectedAddons] = useState({});
    const [makingTime, setMakingTime] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [quantity, setQuantity] = useState(productInfo?.quantity || 1);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState("ALL");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [clickedCategory, setClickedCategory] = useState(null);
    const [categoriesList, setCategoriesList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [inputText, setInputText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [qtyChangingIds, setQtyChangingIds] = useState([]);
    const [isFetchData, setIsFetchData] = useState(false);
    const [isTabChange, setIsTabChange] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingIds, setDeletingIds] = useState([]);

    const navigateToHome = () => {
        navigate('/user-details')
    }

    const handleLogout = () => {
        if (cartId) deleteCart(cartId);
        localStorage.removeItem('cart')
        localStorage.removeItem("cartItems");
        localStorage.removeItem("kioskUser");
        localStorage.removeItem("orderAmount");
        localStorage.removeItem("orderId");
        localStorage.removeItem("cartId");
        localStorage.removeItem("additionalCharges");
        localStorage.removeItem("cartReadyTime");
        localStorage.removeItem("rzp_checkout_anon_id")
        localStorage.removeItem("rzp_device_id")
        navigateToHome()
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        filtereByFoodTypeAndSearch(newValue, inputText, selectedCategory);
    };

    const handleCategoryClick = (categoryId) => {
        setClickedCategory((prevClickedCategory) => {
            if (prevClickedCategory === categoryId) {
                // Reset if the same category is clicked again
                setSelectedCategory(null);
                // setSelectedTab("ALL");
                // setInputText("")
                filtereByFoodTypeAndSearch(selectedTab, inputText, null);
                // getCategoriesWithProducts(); // Fetch all products again
                return null;
            } else {
                // When a new category is clicked, set the category and fetch relevant products
                const selectedCategory = categoriesList.find(
                    (item) => item?._id === categoryId
                );

                if (selectedCategory) {
                    setSelectedCategory(categoryId);
                    // setSelectedTab("ALL");
                    // setInputText("");
                    filtereByFoodTypeAndSearch(selectedTab, inputText, categoryId); // Filter with new category
                    return categoryId;
                }
                return prevClickedCategory;
            }
        });
    };

    const toggleDrawer = (open) => () => {
        setIsDrawerOpen(open);
        setQuantity(1);
        setIsOpen(false);
    };

    const handleClickOpen = (productId) => {
        const product = productsList.find((item) => item._id === productId);

        if (!product) return;

        setProductInfo(product);

        // Initialize selectedAddons with default addons
        const initialAddons = {};
        let initialTotalPrice = product.offerPrice || 0;
        let initialTotalMrp = product.mrp || 0;
        const outlet = localStorage.getItem("outlet");

        product.addons?.forEach((addon) => {
            // Filter out addon values that have excludedOutlet containing the current outlet ID
            const validAddonValues = addon.addonValues?.filter(
                (item) =>
                    !item.excludedOutlets ||
                    !Array.isArray(item.excludedOutlets) ||
                    !item.excludedOutlets.includes(outlet)
            );

            validAddonValues?.forEach((item) => {
                if (item.isDefault) {
                    if (addon.isMultipleSelect) {
                        if (!initialAddons[addon._id]) {
                            initialAddons[addon._id] = [];
                        }
                        initialAddons[addon._id].push({
                            _id: addon._id,
                            addonValueId: item._id,
                            price: item.offerPrice || 0,
                            selectedValue: item.value,
                            mrp: item?.mrp ? item?.mrp : 0,
                            isDefault: true,
                        });
                    } else {
                        initialAddons[addon._id] = {
                            _id: addon._id,
                            addonValueId: item._id,
                            price: item.offerPrice || 0,
                            selectedValue: item.value,
                            mrp: item?.mrp ? item?.mrp : 0,
                            isDefault: true,
                        };
                    }

                    initialTotalPrice += item.offerPrice || 0;
                    initialTotalMrp += item?.mrp != null ? item?.mrp : 0;
                }
            });
        });

        setSelectedAddons(initialAddons);
        setTotalOfferPrice(initialTotalPrice);
        setTotalMrp(initialTotalMrp);
        toggleDrawer(true)();
    };

    const getFoodType = (foodType) => {
        switch (foodType) {
            case "VEG":
                return "/assets/VegImagee.png";
            case "NON-VEG":
                return "/assets/NonVeg.png";
            case "VEGAN":
                return "/assets/Vegan.png";
            case "VEG/VEGAN":
                return ["/assets/VegImagee.png", "/assets/Vegan.png"];
            default:
                return "https://example.com/images/unknown.png";
        }
    };

    // const handleAddonClick = (
    //     addonId,
    //     addonValueId,
    //     addonPrice = 0,
    //     addonName,
    //     addonMrp = 0
    // ) => {
    //     setSelectedAddons((prevSelectedAddons) => {
    //         const newSelectedAddons = { ...prevSelectedAddons };
    //         const isCurrentlySelected = newSelectedAddons[addonId]?.addonValueId === addonValueId;

    //         // Check if the addon has a default value
    //         const hasDefault = productInfo.addons.find((addon) => addon._id === addonId)?.addonValues.some(value => value.isDefault);

    //         if (!isCurrentlySelected) {
    //             // Remove the previous selection for this addon if any
    //             if (newSelectedAddons[addonId]) {
    //                 const currentPrice = newSelectedAddons[addonId].price || 0;
    //                 const currentMrp = newSelectedAddons[addonId].mrp || 0;

    //                 setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
    //                 setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
    //             }

    //             // Set new selection
    //             newSelectedAddons[addonId] = {
    //                 _id: addonId,
    //                 addonValueId: addonValueId,
    //                 price: addonPrice,
    //                 selectedValue: addonName,
    //                 mrp: addonMrp || 0,
    //             };

    //             setTotalOfferPrice((prevTotalPrice) => prevTotalPrice + (addonPrice || 0));
    //             setTotalMrp((prevTotalMrp) => prevTotalMrp + (addonMrp || 0));
    //         } else if (!hasDefault) {
    //             // If there's no default value, allow deselection
    //             const currentPrice = newSelectedAddons[addonId].price || 0;
    //             const currentMrp = newSelectedAddons[addonId].mrp || 0;

    //             delete newSelectedAddons[addonId];

    //             setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
    //             setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
    //         }

    //         return newSelectedAddons;
    //     });
    // };


    // const handleAddonClick = (
    //     addonId,
    //     addonValueId,
    //     addonPrice = 0,
    //     addonName,
    //     addonMrp = 0,
    //     isMultipleSelect = false
    // ) => {
    //     setSelectedAddons((prevSelectedAddons) => {
    //         const newSelectedAddons = { ...prevSelectedAddons };

    //         const addonInfo = productInfo.addons.find((addon) => addon._id === addonId);
    //         const isDefaultValue = addonInfo?.addonValues.find(val => val._id === addonValueId)?.isDefault;
    //         const hasDefault = addonInfo?.addonValues.some(value => value.isDefault);

    //         // Ensure `addonPrice` and `addonMrp` are numbers
    //         const numericAddonPrice = Number(addonPrice) || 0;
    //         const numericAddonMrp = Number(addonMrp) || 0;

    //         if (isMultipleSelect) {
    //             if (!Array.isArray(newSelectedAddons[addonId])) {
    //                 newSelectedAddons[addonId] = [];
    //             }

    //             const existingIndex = newSelectedAddons[addonId].findIndex((item) => item.addonValueId === addonValueId);

    //             if (existingIndex > -1) {
    //                 // Remove the selected addon
    //                 const removedAddon = newSelectedAddons[addonId].splice(existingIndex, 1)[0];

    //                 setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - removedAddon?.price);
    //                 setTotalMrp((prevTotalMrp) => prevTotalMrp - removedAddon?.mrp);
    //             } else {
    //                 // Add the selected addon
    //                 newSelectedAddons[addonId].push({
    //                     _id: addonId,
    //                     addonValueId,
    //                     price: numericAddonPrice,
    //                     selectedValue: addonName,
    //                     mrp: numericAddonMrp,
    //                 });

    //                 setTotalOfferPrice((prevTotalPrice) => prevTotalPrice + numericAddonPrice);
    //                 setTotalMrp((prevTotalMrp) => prevTotalMrp + numericAddonMrp);
    //             }
    //         } else {
    //             // Single select logic
    //             const isCurrentlySelected = newSelectedAddons[addonId]?.addonValueId === addonValueId;

    //             if (!isCurrentlySelected) {
    //                 if (newSelectedAddons[addonId]) {
    //                     const currentPrice = Number(newSelectedAddons[addonId].price) || 0;
    //                     const currentMrp = Number(newSelectedAddons[addonId].mrp) || 0;

    //                     setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
    //                     setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
    //                 }

    //                 newSelectedAddons[addonId] = {
    //                     _id: addonId,
    //                     addonValueId,
    //                     price: numericAddonPrice,
    //                     selectedValue: addonName,
    //                     mrp: numericAddonMrp,
    //                 };

    //                 setTotalOfferPrice((prevTotalPrice) => prevTotalPrice + numericAddonPrice);
    //                 setTotalMrp((prevTotalMrp) => prevTotalMrp + numericAddonMrp);
    //             } else if (!hasDefault) {
    //                 const currentPrice = Number(newSelectedAddons[addonId].price) || 0;
    //                 const currentMrp = Number(newSelectedAddons[addonId].mrp) || 0;

    //                 delete newSelectedAddons[addonId];

    //                 setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
    //                 setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
    //             }
    //         }

    //         return newSelectedAddons;
    //     });
    // };


    const handleAddonClick = (addonId, addonValueId, addonPrice = 0, addonName, addonMrp = 0, isMultipleSelect = false, isDefault = false) => {
        setSelectedAddons((prevSelectedAddons) => {
            const newSelectedAddons = { ...prevSelectedAddons };
            const numericAddonPrice = Number(addonPrice) || 0;
            const numericAddonMrp = Number(addonMrp) || 0;

            if (isMultipleSelect) {
                if (!Array.isArray(newSelectedAddons[addonId])) {
                    newSelectedAddons[addonId] = [];
                }

                const existingIndex = newSelectedAddons[addonId].findIndex(
                    (item) => item.addonValueId === addonValueId
                );

                if (existingIndex > -1) {
                    if (!newSelectedAddons[addonId][existingIndex].isDefault) {
                        newSelectedAddons[addonId].splice(existingIndex, 1);
                        setTotalOfferPrice((prev) => prev - numericAddonPrice);
                        setTotalMrp((prev) => prev - numericAddonMrp);
                    }
                } else {
                    newSelectedAddons[addonId].push({
                        _id: addonId,
                        addonValueId,
                        price: numericAddonPrice,
                        selectedValue: addonName,
                        mrp: numericAddonMrp,
                        isDefault
                    });
                    setTotalOfferPrice((prev) => prev + numericAddonPrice);
                    setTotalMrp((prev) => prev + numericAddonMrp);
                }
            } else {
                newSelectedAddons[addonId] = {
                    _id: addonId,
                    addonValueId,
                    price: numericAddonPrice,
                    selectedValue: addonName,
                    mrp: numericAddonMrp,
                };
                setTotalOfferPrice((prev) => prev + numericAddonPrice);
                setTotalMrp((prev) => prev + numericAddonMrp);
            }

            return newSelectedAddons;
        });
    };

    const preparePayloadWithAddons = (
        productData,
        selectedAddons = {},
        newQuantity
    ) => {
        const productId = productData._id;
        let quantity = Number(productData.quantity) || 0;

        newQuantity = Number(newQuantity) || 0;

        // Add the newQuantity to the existing quantity
        quantity += newQuantity;

        let addons = [];
        if (selectedAddons && Object.keys(selectedAddons).length > 0) {
            addons = Object.values(selectedAddons)
                .flat()
                .map((addon) => ({
                    _id: addon._id,
                    selectedValue: addon.selectedValue,
                    addonValueId: addon.addonValueId,
                    price: addon.price,
                }));
        }

        // Build cartItem payload
        const cartItem = {
            product: productId,
            quantity: quantity,
        };

        // Add addons only if present
        if (addons.length > 0) {
            cartItem.addons = addons;
        }

        const payload = {
            ordersType: "PICKUP",
            outlet: outlet,
            cartItems: [cartItem],
        };

        return payload;
    };

    const preparePayloadWithUpdatedQuantity = (
        productData,
        selectedAddons,
        updatedQuantity
    ) => {
        const productId = productData._id;
        const quantity = Number(updatedQuantity) || 0;

        let addons = [];
        if (selectedAddons && Object.keys(selectedAddons).length > 0) {
            addons = Object.values(selectedAddons).map((addon) => ({
                _id: addon._id,
                selectedValue: addon.selectedValue,
                addonValueId: addon.addonValueId,
                price: addon.price,
                mrp: addon.mrp, // Include mrp field here
            }));
        }

        const cartItem = {
            product: productId,
            quantity: quantity,
        };

        if (addons.length > 0) {
            cartItem.addons = addons;
        }

        const payload = {
            ordersType: "PICKUP",
            outlet: outlet,
            cartItems: [cartItem],
        };

        setIsOpen(true);
        return payload;
    };

    const handleAddProductToCartWithAddons = async (productData) => {
        setIsLoading(true)
        try {
            const token = localStorage.getItem("authToken");
            const product = productsList.find(
                (item) => item._id === productData?._id
            );

            if (!product) {
                return;
            }

            product.quantity = 1;

            let payload;

            if (isOpen) {
                payload = preparePayloadWithUpdatedQuantity(
                    productInfo,
                    selectedAddons,
                    quantity
                );
            } else {
                payload = preparePayloadWithAddons(product, selectedAddons);
            }

            // Make API call to add product to cart
            const response = await axios.post("cart", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
                const cartId = response?.data?.data?.cart?._id;
                localStorage.setItem("cartId", cartId);

                toast.success(message, {
                    position: "top-center",
                    autoClose: 2000,
                    theme: "colored",
                    type: "info"
                });
                setIsDrawerOpen(false);
                getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
        finally {
            setIsLoading(false)
        }
    };

    const getCartItems = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/userCart/${outlet}/PICKUP`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                const cartItems = response?.data?.data?.cartItems;

                let items = cartItems.filter(
                    (item) => item?.product?.isDelete === false
                );

                const readyTime = items?.reduce((acc, item) => {
                    const makingTime = Number(item?.product?.makingTime);
                    return acc + (isNaN(makingTime) ? 0 : makingTime);
                }, 0);

                setMakingTime(readyTime)
                setCartItems(items);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const deleteCartItem = async (id) => {
        setIsDeleting(true)
        setDeletingIds((prevIds) => [...prevIds, id]);
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartItemIds: [id],
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                setCartItems((prevItems) =>
                    prevItems.filter((item) => item._id !== id)
                );
                const message = response?.data?.data?.message;
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleting(false)
            setDeletingIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
        }
    };

    const handleQuantityChange = async (productId, newQuantity) => {
        const previousCartItems = [...cartItems];

        setCartItems((prevItems) =>
            prevItems.map((item) =>
                item._id === productId ? { ...item, quantity: newQuantity } : item
            )
        )
        setQtyChangingIds((prevIds) => [...prevIds, productId]);

        try {
            if (newQuantity === 0) {
                deleteCartItem(productId);
                return;
            }

            const token = localStorage.getItem("authToken");

            const data = {
                cartItems: [
                    {
                        _id: productId,
                        quantity: newQuantity
                    }

                ]
            }

            const response = await axios.put("cart", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                getCartItems();
            }
        } catch (error) {
            setCartItems(previousCartItems);
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setQtyChangingIds((prevIds) => prevIds.filter((id) => id !== productId));
        }
    };

    const handleInputChange = (e) => {
        const searchText = e.target.value;
        setInputText(searchText);
        filtereByFoodTypeAndSearch(selectedTab, searchText, selectedCategory);
    };

    const handleChangeQuantity = (change) => {
        const updatedQuantity = quantity + change;

        // Prevent quantity from being 0 or less
        if (updatedQuantity < 1) {
            return;
        }

        handleProdQtyChange(change, productInfo, setProductInfo);
        setQuantity(updatedQuantity);
        // Prepare payload with updated quantity
        const payload = preparePayloadWithUpdatedQuantity(
            productInfo,
            selectedAddons,
            updatedQuantity
        );
        // Use the payload as needed
    };

    const handleProdQtyChange = (change, productInfo, setProductInfo) => {
        setProductInfo((prevInfo) => ({
            ...prevInfo,
            quantity: Math.max(prevInfo.quantity + change, 0),
        }));
    };

    const getCategoriesWithProducts = async () => {
        setIsFetchData(true)
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(
                `masters/category/getCategoryWithProducts/${outlet}`,
                // {
                //     headers: {
                //         Authorization: `Bearer ${token}`,
                //     },
                // }
            );

            if (response?.status === 200) {
                const categories = response?.data?.data?.categories;
                let filteredCategories;
                let products
                let filteredProducts

                if (isEventOutlet) {
                    filteredCategories = categories?.filter((item) => item?.isEventCategory === true)
                } else {
                    filteredCategories = categories?.filter((item) => item?.isEventCategory === false)
                }

                setCategoriesList(filteredCategories);

                products = filteredCategories.map((cat) => cat.products).flat();

                if (isEventOutlet) {
                    filteredProducts = products?.filter((item) => item?.isEventProduct === true)
                } else {
                    filteredProducts = products?.filter((item) => item?.isEventProduct === false)
                }

                // **Filter out products that contain the outlet in their `excludedOutlet` array**
                filteredProducts = filteredProducts.filter(
                    (product) => !(product.excludedOutlets && Array.isArray(product.excludedOutlets) && product.excludedOutlets.includes(outlet))
                );

                setProductsList(filteredProducts);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsFetchData(false)
        }
    };

    const filtereByFoodTypeAndSearch = async (foodType, text, categoryId) => {
        setIsTabChange(true);
        try {
            const token = localStorage.getItem("authToken");
            let url = `masters/category/getCategoryWithProducts/${outlet}`;

            const params = [];
            if (foodType && foodType !== "ALL") {
                params.push(`foodType=${foodType}`);
            }
            if (text) {
                params.push(`search=${text}`);
            }
            if (categoryId) {
                params.push(`categoryId=${categoryId}`);
            }

            if (params.length > 0) {
                url += `?${params.join("&")}`;
            }

            const response = await axios.get(url,
                //  {
                // headers: { Authorization: `Bearer ${token}` },
                // }
            );

            if (response?.status === 200) {
                const categories = response?.data?.data?.categories;
                let filteredCategories;
                let products;

                if (isEventOutlet) {
                    filteredCategories = categories.filter((item) => item?.isEventCategory === true);
                } else {
                    filteredCategories = categories.filter((item) => item?.isEventCategory === false);
                }

                products = filteredCategories
                    .map((cat) => cat.products)
                    .flat()
                    .filter((item) =>
                        isEventOutlet ? item.isEventProduct === true : item.isEventProduct === false
                    );
                setProductsList(products);


                // if (categoryId) {
                //     // Filter products by selected category
                //     const category = filteredCategories.find((item) => item?._id === categoryId);
                //     const products = category
                //         ? category.products.filter((item) =>
                //             isEventOutlet ? item?.isEventProduct === true : item?.isEventProduct === false
                //         )
                //         : [];
                //     setProductsList(products);
                // } else {
                //     // No category selected; flatten and filter products across all categories
                //     const products = filteredCategories
                //         .map((cat) => cat.products)
                //         .flat()
                //         .filter((item) =>
                //             isEventOutlet ? item.isEventProduct === true : item.isEventProduct === false
                //         );
                //     setProductsList(products);
                // }
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                switch (error.response?.status) {
                    case 406:
                        errorMessage = error.response?.data?.error?.message || error.response?.data?.error;
                        break;
                    case 412:
                        errorMessage = error.response?.data?.data?.message;
                        break;
                    case 500:
                        errorMessage = error.response?.data?.error;
                        break;
                    default:
                        errorMessage = "An unexpected error occurred.";
                }
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsTabChange(false);
        }
    };

    const deleteCart = async (id) => {
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartId: id,
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    console.log("categoriesList", categoriesList);


    // useEffect(() => {
    //     if (!productInfo) return;

    //     const basePrice = productInfo.offerPrice || 0;
    //     const addonsPrice = Object.values(selectedAddons).reduce(
    //         (total, addon) => total + addon.price,
    //         0
    //     );
    //     productInfo.quantity = 1;
    //     setTotalOfferPrice(basePrice + addonsPrice);
    // }, [selectedAddons, productInfo]);

    useEffect(() => {
        if (!productInfo) return;

        const basePrice = Number(productInfo.offerPrice) || 0;

        const addonsPrice = Object.values(selectedAddons).reduce((total, addon) => {
            if (!addon) return total; // Skip undefined values

            if (Array.isArray(addon)) {
                // If addon is an array (for multi-select addons), sum all prices
                return total + addon.reduce((sum, item) => sum + (Number(item.price) || 0), 0);
            }

            return total + (Number(addon.price) || 0); // Ensure price is a number
        }, 0);
        productInfo.quantity = 1;
        setTotalOfferPrice(basePrice + addonsPrice);
    }, [selectedAddons, productInfo]);

    useEffect(() => {
        if (cartId) getCartItems();
        setSelectedTab("ALL");
        setSelectedCategory(null);
        setInputText("")

        // Get categories if no search input or if all tab is selected
        if (inputText === "" && selectedTab === "ALL" && selectedCategory === null) {
            getCategoriesWithProducts();
        } else {
            filtereByFoodTypeAndSearch(selectedTab, inputText, selectedCategory)
        }

    }, []);

    {
        isFetchData && <Box sx={{ width: '50%', margin: 'auto', marginTop: '600px', height: "calc(100vh - 240px)" }}>
            <CircularProgress size={24} />
        </Box>
    }

    return (
        <>
            <AppBar position="fixed" color="inherit" elevation={0} >
                <Toolbar sx={{ padding: '10px', background: '#dfeef5' }}>
                    {/* Brand Logo */}
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <img src="/assets/brand-logo.png" alt="Brand Logo" style={{ height: 50, marginRight: 5 }} />
                    </Box>

                    {/* Search Bar */}
                    <Box sx={{ flexGrow: 2, display: 'flex', justifyContent: 'center' }}>
                        <FormControl sx={{ width: "70%" }}>
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon sx={{ color: '00b0ff' }} />
                                    </InputAdornment>
                                }
                                onChange={handleInputChange}
                                placeholder="Search for the perfect brew"
                                sx={{ background: "white" }}
                            />
                        </FormControl>
                    </Box>

                    {/* Logout Button */}
                    <Box sx={{ flexGrow: 0 }}>
                        <Button
                            variant="contained"
                            onClick={handleLogout}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '8px 12px',
                                backgroundColor: '#fff',
                                color: '#000',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        >
                            <LogoutIcon sx={{ mb: 0.5, color: '#000' }} />
                            Close
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <Box sx={{
                background: '#dfeef5',
                scrollbarWidth: 'none',
                height: '100vh'
            }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Box sx={{ width: '63%' }}>
                        <Box sx={{
                            height: "240px",
                            position: "fixed",
                            width: "63%",
                            bgcolor: "white",
                            top: 87,
                            zIndex: 1,
                            overflowX: 'hidden',
                            scrollbarWidth: 'none',
                            borderTopRightRadius: '50px',
                        }}>
                            <Box
                                sx={{
                                    width: '93%',
                                    height: '120px',
                                    overflowX: 'auto',
                                    scrollbarWidth: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    padding: '18px',
                                    margin: 'auto'
                                }}
                            >
                                {categoriesList?.map((item, index) => (
                                    <Box
                                        key={index}
                                        onClick={() => handleCategoryClick(item._id)}
                                        sx={{
                                            marginRight: '16px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                borderRadius: '50%',
                                                width: 71,
                                                height: 71,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                                border: clickedCategory === item._id ? '2px solid #03a9f5' : '2px solid transparent',
                                            }}
                                        >
                                            <img
                                                src={item?.imageURL}
                                                alt="category"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: 'cover',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        </Box>

                                        <Typography variant="body2" sx={{
                                            color: '#000',
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                            textAlign: 'center',
                                            marginTop: '4px',
                                            fontStyle: 'roboto'
                                        }}>
                                            {item?.name.length > 12 ? `${item?.name.split(" ")[0]}...` : item?.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="center" gap={2} ml={1} sx={{ width: '80%' }}>
                                <Typography variant="body1" color="textSecondary">
                                    Filter By
                                </Typography>

                                <Box
                                    sx={{
                                        backgroundColor: '#e3f2fd',
                                        borderRadius: 4,
                                        padding: '5px',
                                        display: 'flex',
                                    }}
                                >
                                    <Button
                                        onClick={(event) => handleTabChange(event, 'ALL')}
                                        sx={{
                                            color: selectedTab === 'ALL' ? '#fff' : '#636363',
                                            backgroundColor: selectedTab === 'ALL' ? '#03a9f5' : 'transparent',
                                            '&:hover': {
                                                backgroundColor: selectedTab === 'ALL' ? '#039be5' : '#f1f1f1',
                                                color: selectedTab === 'ALL' ? 'white' : '#03a9f5',
                                            },
                                            borderRadius: '20px',
                                            padding: '5px 15px',
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                            marginRight: '10px',
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={(event) => handleTabChange(event, 'VEG')}
                                        sx={{
                                            color: selectedTab === 'VEG' ? '#fff' : '#636363',
                                            backgroundColor: selectedTab === 'VEG' ? '#03a9f5' : 'transparent',
                                            '&:hover': {
                                                backgroundColor: selectedTab === 'VEG' ? '#039be5' : '#f1f1f1',
                                                color: selectedTab === 'VEG' ? 'white' : '#03a9f5',
                                            },
                                            borderRadius: '50px',
                                            padding: '5px 20px',
                                            minWidth: '100px',
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Veg
                                    </Button>
                                    <Button
                                        onClick={(event) => handleTabChange(event, 'NON-VEG')}
                                        sx={{
                                            color: selectedTab === 'NON-VEG' ? '#fff' : '#636363',
                                            backgroundColor: selectedTab === 'NON-VEG' ? '#03a9f5' : 'transparent',
                                            '&:hover': {
                                                backgroundColor: selectedTab === 'NON-VEG' ? '#039be5' : '#f1f1f1',
                                                color: selectedTab === 'NON-VEG' ? 'white' : '#03a9f5',
                                            },
                                            borderRadius: '20px',
                                            padding: '5px 15px',
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        Non Veg
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                        {/* PRODUCTS LISTING  */}

                        {
                            isFetchData ? <Box sx={{ width: '50%', margin: 'auto', marginTop: '500px', height: "calc(100vh - 240px)" }}>
                                <CircularProgress size={24} />
                            </Box> : isTabChange ? <Box sx={{ width: '50%', margin: 'auto', marginTop: '500px', height: "calc(100vh - 240px)" }}>
                                <CircularProgress size={24} />
                            </Box> : <Box
                                sx={{
                                    flex: "0 0 63%",
                                    bgcolor: "white",
                                    marginTop: "320px",
                                    overflowY: "auto",
                                    scrollbarWidth: 'none',
                                    height: "calc(100vh - 350px)",
                                    borderRadius: 2,
                                    padding: "10px 0px"
                                }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        minHeight: "calc(100vh - 240px)",
                                        overflowY: "auto",
                                        scrollbarWidth: 'none',
                                        width: "100%",
                                    }}
                                >
                                    {productsList && productsList.length > 0 ? (
                                        productsList.map((product, index) => {
                                            return (
                                                <Grid
                                                    key={product._id}
                                                    item
                                                    xs={12} sm={4} md={4}
                                                >
                                                    <ProductCard
                                                        product={product}
                                                        handleClickOpen={handleClickOpen}
                                                        getFoodType={getFoodType}
                                                        quantity={quantity}
                                                        index={index}
                                                        cartItems={cartItems}
                                                        productsList={productsList}
                                                    />
                                                </Grid>
                                            );
                                        })
                                    ) : (
                                        <Box sx={{ width: '50%', margin: 'auto', marginTop: '300px' }}>
                                            No Products Found
                                        </Box>
                                    )}
                                </Grid>
                            </Box>
                        }
                    </Box>
                    {/* CART LISTING  */}
                    <Box sx={{ flex: "0 0 33%", overflowY: "auto", background: '#dfeef5', marginRight: '2%' }}>
                        {cartItems && cartItems.length > 0 ? (
                            <Cart
                                cartItems={cartItems}
                                makingTime={makingTime}
                                handleQuantityChange={handleQuantityChange}
                                qtyChangingIds={qtyChangingIds}
                                isDeleting={isDeleting}
                                deletingIds={deletingIds}
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    backgroundColor: "#f9f9f9",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    overflow: "hidden",
                                    height: "100vh",
                                    width: "33%",
                                    position: "fixed",
                                    right: 0,
                                    top: 90,
                                    height: "100vh",
                                    bgcolor: "white",
                                    borderTopLeftRadius: '40px',
                                    marginRight: '10px'
                                }}
                            >
                                <img
                                    src="/assets/empty-cart.png"
                                    width={"60%"}
                                    style={{ marginBottom: "16px" }}
                                />
                                <Typography variant="h6" sx={{ color: "#555" }}>
                                    Your cart is currently empty
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Stack>
            </Box>

            <Box sx={{ overflow: "hidden" }}>
                <Drawer
                    anchor="bottom"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    transitionDuration={500}
                    PaperProps={{
                        sx: {
                            maxWidth: "500px",
                            margin: "auto",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            padding: 3,
                            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                        },
                    }}
                >
                    {/* Fixed Top Section */}
                    <Box>
                        <Stack direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                            {/* Product Image */}
                            <Box sx={{ width: "100px", height: "100px", flexShrink: 0 }}>
                                <img
                                    src={productInfo?.displayImageUrl}
                                    alt="product-image"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Box>

                            {/* Product Details */}
                            <Box>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width={"100%"}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={toggleDrawer(false)}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 12,
                                                color: "#222124",
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} mt={1} gap={1}>
                                    <Box mb={1} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        {Array.isArray(getFoodType(productInfo?.foodType)) ? (
                                            getFoodType(productInfo?.foodType).map((url, index) => (
                                                <img
                                                    key={index}
                                                    src={url}
                                                    alt={`Food type image ${index}`}
                                                    width={18}
                                                    style={{ marginRight: 4 }}
                                                />
                                            ))
                                        ) : (
                                            <img
                                                src={getFoodType(productInfo?.foodType)}
                                                alt="Food type"
                                                width={18}
                                            />
                                        )}
                                    </Box>
                                    {
                                        (productInfo?.weight != null && productInfo?.weightUnit) && (
                                            <Box sx={{ marginTop: '-5px' }}>
                                                <Typography sx={{ fontWeight: 'bold', color: "text.secondary" }}>
                                                    {productInfo.weight} {productInfo.weightUnit}
                                                </Typography>
                                            </Box>
                                        )
                                    }

                                    {
                                        productInfo?.calories != null && (
                                            <Box sx={{ marginTop: '-5px' }}>
                                                <Typography sx={{ fontWeight: 'bold', color: "text.secondary" }}>
                                                    | {productInfo.calories} Cal
                                                </Typography>
                                            </Box>
                                        )
                                    }

                                </Stack>
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", lineHeight: 1, fontSize: '17px' }}
                                >
                                    {productInfo?.name}
                                </Typography>
                                <Typography mt={1} variant="body2" sx={{ fontWeight: 'bold', color: "text.secondary", fontSize: '12px' }}>
                                    {productInfo?.description}
                                </Typography>
                                <Stack direction={"row"} spacing={1} flexWrap="wrap" mt={1}>
                                    {productInfo?.productsNature?.map((nature, index) => (
                                        <Chip key={index} label={nature} variant="contained" sx={{ background: "#dfedf4", color: '#466674' }} />
                                    ))}
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>

                    {/* Scrollable Content */}
                    {productInfo?.addons && productInfo.addons.length > 0 && (
                        <Box sx={{
                            flex: 1, overflowY: "auto", background: '#f0f4f5', padding: '10px', height: '400px', maxHeight: '400px', position: 'relative',
                            "&::-webkit-scrollbar": {
                                width: "6px",
                                height: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#00b0ff",
                                borderRadius: "50px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#00b0ff",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#c6c8ca",
                            },
                        }}>
                            {/* Addons Section */}
                            <Box>
                                {productInfo?.addons
                                    ?.filter(addon =>
                                        // Exclude addons that have excludedOutlets containing the current outlet
                                        (!addon.excludedOutlets || !addon.excludedOutlets.includes(outlet)) &&
                                        // Ensure at least one valid addonValue is available
                                        addon.addonValues.some(item => !item.excludedOutlets || !item.excludedOutlets.includes(outlet))
                                    )
                                    .map((addon, index) => (
                                        <Box key={addon._id} sx={{ marginBottom: 1, background: 'white', padding: '10px', borderRadius: '30px' }}>
                                            <Stack direction="row" spacing={1} justifyContent="space-between">
                                                <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: '14px', marginTop: '15px' }}>
                                                    {addon.addonTitle}
                                                </Typography>
                                                <Stack direction={addon?.addonTitle === "Size" ? 'row' : 'column'} spacing={2} flexWrap="wrap">
                                                    {addon.addonValues
                                                        .filter(item => !item.excludedOutlets || !item.excludedOutlets.includes(outlet))
                                                        .map((item) => {
                                                            const isSelected = Array.isArray(selectedAddons[addon._id])
                                                                ? selectedAddons[addon._id].some(selected => selected.addonValueId === item._id)
                                                                : selectedAddons[addon._id]?.addonValueId === item._id;

                                                            const showImage = addon.addonTitle === "Size";
                                                            let imgSrc = showImage ? `/assets/${item.value.toLowerCase()}-${isSelected ? 'full' : 'empty'}.jpeg` : "";
                                                            let imgStyle = { width: '50px', height: '50px', cursor: 'pointer' };

                                                            if (showImage) {
                                                                if (item.value === "Medium") {
                                                                    imgSrc = `/assets/medium-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                                    imgStyle = { width: '26px', height: '45px', cursor: 'pointer' };
                                                                } else if (item.value === "Large") {
                                                                    imgSrc = `/assets/large-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                                    imgStyle = { width: '25px', height: '55px', cursor: 'pointer' };
                                                                } else if (item.value === "Small" || item.value === "Regular") {
                                                                    imgSrc = `/assets/small-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                                    imgStyle = { width: '20px', height: '35px', cursor: 'pointer' };
                                                                }
                                                            }

                                                            return (
                                                                <Stack key={item._id} direction="column" alignItems="center" justifyContent="flex-end">
                                                                    {showImage && (
                                                                        <Box>
                                                                            <img
                                                                                src={imgSrc}
                                                                                alt={item.value}
                                                                                onClick={() => handleAddonClick(
                                                                                    addon._id,
                                                                                    item._id,
                                                                                    item.offerPrice || 0,
                                                                                    item.value,
                                                                                    item.mrp || 0,
                                                                                    addon?.isMultipleSelect,
                                                                                    item.isDefault
                                                                                )}
                                                                                style={imgStyle}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                    <Button
                                                                        variant={isSelected ? "contained" : "outlined"}
                                                                        onClick={() => handleAddonClick(
                                                                            addon._id,
                                                                            item._id,
                                                                            item.offerPrice || 0,
                                                                            item.value,
                                                                            item.mrp || 0,
                                                                            addon?.isMultipleSelect,
                                                                            item.isDefault
                                                                        )}
                                                                        sx={{
                                                                            textTransform: "none",
                                                                            margin: "4px",
                                                                            fontSize: "0.81rem",
                                                                            borderRadius: "20px",
                                                                            padding: "1px 16px",
                                                                            backgroundColor: isSelected ? "#00b0ff" : "transparent",
                                                                            color: isSelected ? "#fff" : "inherit",
                                                                            borderColor: isSelected ? "transparent" : "#00b0ff",
                                                                            "&:hover": {
                                                                                backgroundColor: isSelected ? "#00b0ff" : "#bfbfbf",
                                                                                borderColor: isSelected ? "transparent" : "#bfbfbf",
                                                                            },
                                                                        }}
                                                                    >
                                                                        {item.value} <span style={{ color: isSelected ? "green" : "inherit", marginLeft: '5px' }}>
                                                                            {item.offerPrice ? `+₹${item.offerPrice}` : <span style={{ color: "green" }}>+₹0</span>}
                                                                        </span>
                                                                    </Button>
                                                                </Stack>
                                                            );
                                                        })}
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    ))}
                            </Box>

                        </Box>
                    )
                    }

                    {/* Price and Actions */}
                    <Box sx={{ paddingTop: 2, borderTop: "1px solid #ddd" }}>
                        <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
                            <Box sx={{ width: '30%' }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Remove
                                        onClick={() => handleChangeQuantity(-1)}
                                        className="cursor-p"
                                        style={{
                                            fontSize: "2rem",
                                            color: "#fff",
                                            backgroundColor: "#00b0ff",
                                            borderRadius: "50px",
                                        }}
                                    />
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        sx={{
                                            width: "30px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography sx={{ fontSize: 22 }}>{quantity}</Typography>
                                    </Stack>
                                    <Add
                                        onClick={() => handleChangeQuantity(1)}
                                        className="cursor-p"
                                        style={{
                                            fontSize: "2rem",
                                            color: "#fff",
                                            backgroundColor: "#00b0ff",
                                            borderRadius: "50px",
                                        }}
                                    />
                                </Stack>
                            </Box>


                            <Box sx={{ width: '60%' }}>
                                <PrimaryButton
                                    title={`Add Item | ₹ ${(quantity * (Number(totalOfferPrice) || 0)).toFixed(2)}`}
                                    onClick={() => handleAddProductToCartWithAddons(productInfo)}
                                    disabled={isLoading}
                                    spinner={isLoading}
                                    disabledTitle={"Please Wait..."}
                                    style={{
                                        borderRadius: "30px", fontSize: "18px",
                                        width: '100%',
                                        background: '#00b0ff',
                                    }}
                                />
                            </Box>
                        </Stack>


                    </Box>
                </Drawer>
            </Box>
        </>
    );
};

export default Products;
